<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-template-text</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Templates ({{ totalTemplates }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div
      class="mx-4"
      id="template-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-data-table
        :headers="headers"
        :items="templates"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalTemplates"
        @click:row="editItem"
        @update:options="fetch"
      >
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(templates.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Template list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'TemplatesPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'header',
      sortOrder: true,
      headers: [
        {
          text: 'Template',
          value: 'name',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'From',
          value: 'from',
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
      ],
      selected: [],
      actions: [],
      options: {},
      defaultStatus: true,
    }
  },

  created() {
    this.clearTemplates()
    this.fetch(this.options)
  },

  computed: {
    ...mapState({
      templates: (state) => state.template.list,
      listMeta: (state) => state.template.listMeta,
      permissions: (state) => state.auth.permissions,
      totalTemplates: (state) => state.template.totalTemplates,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.TEMPLATES_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getTemplates: 'template/getTemplates',
    }),

    ...mapMutations({
      clearTemplates: 'template/clearTemplateList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearTemplates()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearTemplates()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearTemplates()
      this.fetch(this.options)
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalTemplates
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options?.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getTemplates(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'settings.organisation.template.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
