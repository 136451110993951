<template>
  <div class="mx-4" id="organisation-item-list">
    <div class="appbar mt-7 mb-3">
      <v-tabs v-model="tabDefault">
        <v-tab
          class="px-4"
          v-for="item in tabItems"
          :key="item.value"
          :tab-value="item.value"
          :ref="item.value"
          :disabled="tabDefault === item.value || loading || item.disable"
          @click="handleTabClick(item)"
        >
          {{ item.tab }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="ma-4">
      <!-- Render corresponding component based on selected tab -->
      <General v-if="tabDefault === 'general'" />
      <SsoProvider v-if="tabDefault === 'sso'" />
      <Templates v-if="tabDefault === 'templates'" />
    </div>
  </div>
</template>

<script>
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import General from './General'
import SsoProvider from './SsoProvider'
import Templates from './Templates'

export default {
  name: 'Organisation',
  components: {
    SsoProvider,
    Templates,
    General,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  data() {
    return {
      loading: false,
      option: {},
      tabItems: [
        { tab: 'General', value: 'general', disable: false, index: 0 },
        { tab: 'SSO', value: 'sso', disable: false, index: 1 },
        { tab: 'Integrations', value: 'integrations', disable: true, index: 2 },
        { tab: 'Templates', value: 'templates', disable: false, index: 3 },
      ],
      // Default to the first enabled tab (SSO in this case)
      tabDefault: 'general',
    }
  },

  mounted() {
    // Set initial tab from route or default
    this.setInitialTabFromRoute()
  },
  watch: {
    // Watch route changes to update the active tab
    $route(to) {
      this.updateTabFromRoute(to.params.tab)
    },
  },
  methods: {
    // Handle tab click and update URL accordingly
    handleTabClick(item) {
      if (this.$route.params.tab !== item.value) {
        this.tabDefault = item.value
        this.$router.push({
          name: 'settings.organisation',
          params: { tab: item.value },
        })
      }
    },

    // Set initial tab based on the current route
    setInitialTabFromRoute() {
      const currentTab = this.$route.params.tab || this.tabDefault
      this.updateTabFromRoute(currentTab)
    },

    // Update tabDefault based on route param
    updateTabFromRoute(tab) {
      const tabExists = this.tabItems.some((item) => item.value === tab)
      this.tabDefault = tabExists ? tab : this.tabDefault
    },
  },
}
</script>
