<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-row class="padding-bottom-0">
      <v-col cols="12">
        <h1 class="py-5">Organisation Overview</h1>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-field-label">Organisation Name</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Organisation Name"
          v-model="form.name"
          :error-messages="form.$getError('name')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">ABN</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="ABN"
          v-model="form.abn"
          :error-messages="form.$getError('abn')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">Website</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Website"
          v-model="form.website"
          :error-messages="form.$getError('website')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">Phone</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Phone"
          v-model="form.phone"
          :error-messages="form.$getError('phone')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">Email</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Email"
          v-model="form.email"
          :error-messages="form.$getError('email')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">Address</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Address"
          v-model="form.address"
          :error-messages="form.$getError('address')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <label class="text-field-label">Primary Colour (hex code)</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Primary Colour (hex code)"
          v-model="form.primary_colour"
          :error-messages="form.$getError('primary_colour')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <div class="mt-10">
          <v-btn
            type="submit"
            color="primary"
            class="mr-4 px-6"
            height="40px"
            width="100%"
            :loading="form.$busy"
            >{{ buttonLabel }}</v-btn
          >
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-field-label">Logo</label>
        <OrganisationLogoUpload
          ref="logoUploader"
          class="mt-2 mb-4"
          :loading="form.$busy"
          :error="form.$getError('logo')"
          @updated="form.$clearError('logo')"
        />
      </v-col>

      <Loading :value="loading" />
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import ROLE from '@/utils/enums/Role'
import startCase from 'lodash/startCase'
import Loading from '@/components/common/Loading.vue'
import OrganisationLogoUpload from '@/views/Home/Settings/Organisation/General/components/OrganisationLogoUpload.vue'

export default {
  name: 'Organisation',
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  components: {
    OrganisationLogoUpload,
    Loading,
  },

  data() {
    return {
      loading: false,
      form: new Form({
        name: '',
        abn: '',
        website: '',
        phone: '',
        email: '',
        address: '',
        primary_colour: '',
        logo: '',
        type: 'organisation',
      }),
    }
  },

  watch: {
    organisation(newValue, oldValue) {
      this.initForm()
    },
  },

  created() {
    this.fetchOrganisation()
  },

  computed: {
    ...mapState({
      organisation: (state) => state.organisation.organisationDetail,
    }),

    buttonLabel() {
      return 'Save Changes'
    },

    roles() {
      return Object.keys(ROLE).map((key) => {
        return {
          text: startCase(key),
          value: ROLE[key],
        }
      })
    },
  },

  methods: {
    ...mapActions({
      createOrganisation: 'organisation/createOrganisation',
      getOrganisationDetails: 'organisation/getOrganisationDetails',
    }),

    async fetchOrganisation() {
      this.loading = true
      await this.getOrganisationDetails()
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    initForm() {
      if (this.organisation) {
        const content = JSON.parse(this.organisation.content)
        this.form.name = content.name
        this.form.abn = content.abn
        this.form.website = content.website
        this.form.phone = content.phone
        this.form.email = content.email
        this.form.address = content.address
        this.form.primary_colour = content.primary_colour
        this.form.logo = this.organisation.logo
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.createOrganisation(this.getFormData())
        .then(() => {
          this.showSnackbar('Organisation successfully created!')
          this.$router.push({
            name: 'settings.organisation',
          })
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      return !this.form.$hasErrors()
    },

    getFormData() {
      const form = this.form.$data()

      const formData = new FormData()

      if (this.form.logo instanceof File || this.form.logo instanceof Blob) {
        formData.append('logo', this.$refs.logoUploader.getImage())
      }
      formData.append('type', this.form.type)

      delete this.form.logo
      delete this.form.type

      let content = []
      Object.keys(form).forEach((key) => {
        content[key] = form[key]
      })

      formData.append('content', JSON.stringify(Object.assign({}, content)))

      return formData
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-xml-file {
  border-radius: 6px;
  padding: 0 !important;
}
.file-name-wrapper {
  margin: auto 0;
}
::v-deep .upload-xml-file {
  .v-input__prepend-outer {
    width: 100%;
    height: 100%;
    margin-right: 0;

    .v-input__icon.v-input__icon--prepend {
      height: 100%;
    }
  }
  button.v-icon.v-icon--link {
    color: white;
    font-size: 1rem;
    width: 100%;
    min-height: 100%;
  }
}
</style>
