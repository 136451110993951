<template>
  <div class="organisation-logo-upload">
    <FileUpload
      :image="form.logo"
      :aspect-ratio="1.89"
      :initial-aspect-ratio="1.89"
      :logo="true"
      :loading="form.$busy || loading"
      :error="error"
      :disable-delete="true"
      @input="toUpload"
      :showUploadImageBtn="showUploadImageBtn"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building: Logo Upload
 * ==================================================================================
 **/

import { mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import FileUpload from '@/components/fields/FileUpload'

export default {
  name: 'OrganisationLogoUpload',
  components: {
    FileUpload,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    forUpdate: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [String, Array],
      default: null,
    },
    showUploadImageBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: new Form({
        logo: null,
      }),
    }
  },

  computed: {
    ...mapState({
      organisation: (state) => state.organisation.organisationDetail,
    }),
  },

  watch: {
    organisation: {
      handler(newValue, oldValue) {
        this.initForm()
      },
      deep: true,
    },
  },

  mounted() {
    this.initForm()
  },

  methods: {
    initForm() {
      if (this.organisation) {
        this.form.logo = this.organisation.logo
      }
    },

    async toUpload(image) {
      this.form.logo = image

      this.$emit('updated')
    },

    getImage() {
      return this.form.logo ? this.form.logo.file : null
    },

    hasData() {
      return !!this.getImage()
    },
  },
}
</script>
<style lang="scss" scoped>
.organisation-logo-upload {
  width: 100%;
}
</style>
